.filter-options-scroll {
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  width: 657px;
  position: absolute;
  z-index: 9999;
  height: 100px;
  overflow-y: scroll;
}
.filter-options-noscroll {
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  width: 657px;
  position: absolute;
  z-index: 9999;
  height: fit-content;
}
input {
  width: 100%;
  height: 35px;
  font-size: 24px;
}
.book-title-span {
  font-size: 18px;
  width: 100%;
  display: flex;
}
.book-title-span:hover {
  background-color: #32354f;
  color: white;
  cursor: pointer;
}
