@font-face {
  font-family: "Circular";
  src: url("./fonts/Circular/CircularStd-Light.otf") format("opentype");
}

.abc {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  overflow-x: hidden;
}
body {
  background-color: rgb(30, 32, 48);
  color: white;
  overflow-x: hidden;
  /* overflow-y: hidden; */
  font-family: Circular;
}
.full-page-container {
  /* border: 1px solid black; */
  border-radius: 10px;
  width: 75%;
  height: 100%;
  margin-bottom: 100px;
}
a {
  color: white;
}
.blur {
  filter: blur(20px);
  width: 250px;
}
.no-blur {
  width: 250px;
  border-radius: 20px;
}
.guess-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -50px;
  font-size: 18px;
}
.guess-input {
  margin-top: 50px;
  margin-bottom: 20px;
  width: 30%;
  height: 30px;
  border-radius: 10px;
}
.shake-screen {
  animation: shake 0.5s infinite;
  overflow-y: hidden;
  overflow-x: hidden;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-5px);
  }
  40% {
    transform: translateX(5px);
  }
  60% {
    transform: translateX(-5px);
  }
  80% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.falling-number {
  opacity: 1;
  font-size: 20px;
  animation: fallAndFade 2s ease-in forwards;
  color: red;
  margin-left: 10px;
}

@keyframes fallAndFade {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(
      50px
    ); /* Adjust the distance the number falls as needed */
    opacity: 0.5;
  }
  100% {
    transform: translateY(100px);
    opacity: 0;
  }
}
.hint-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: -20px; */
  margin-bottom: 500px;
}
.right-hint-top {
  width: 325px;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
  margin-top: 5px;
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
  height: 70%;
  margin-left: 15px;
  cursor: default;
}
.right-hint-bottom {
  width: 325px;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
  margin-top: 5px;
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
  height: 30%;
  margin-left: 15px;
  cursor: default;
}
.left-hint-top {
  width: 325px;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
  margin-top: 5px;
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
  height: 50%;
  margin-right: 15px;
  cursor: default;
}
.left-hint-bottom {
  width: 325px;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
  margin-top: 5px;
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
  height: 50%;
  margin-right: 15px;
  cursor: default;
}
.game-over-hint {
  width: fit-content;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
  margin-top: 5px;
}
@keyframes fadeIn {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(
      0
    ); /* Adjust the distance the number falls as needed */
    opacity: 1;
  }
}
.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #1e2030;
  width: 400px;
  /* margin-left: 550px; */
  margin-top: 100px;
  padding: 30px;
  border-radius: 15px;
}
li {
  list-style-type: disc;
}
.submit-button {
  width: 10%;
  border-radius: 10px;
  border: 1px solid #1f244a;
  background-color: olive;
  color: white;
  height: 35px;
  cursor: pointer;
  font-family: Circular;
}
.submit-button:hover {
  transform: scale(1.1);
  transition: transform 0.1s ease-in-out;
  font-family: Circular;
}

.info-icon:hover {
  transform: scale(1.3);
  transition: transform 0.1s ease-in-out;
}
